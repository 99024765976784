import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from './productsQueries';
import { TTutorial } from './tutorialQueries';

export type TSoftware = {
  name: {
    key: string;
    value: string;
  }[];
  deviceCategory: any;
  image: string;
  latestBuild: any;
  versions: {
    howToUpdate?: TTutorial;
    number: string;
    fileName: string;
    fileURL: string;
    changelog: {
      key: string;
      value: string;
    }[];
  }[];
  _id: string;
  isActive?: boolean;
};

export const softwareApi = createApi({
  reducerPath: 'software',
  baseQuery,
  tagTypes: ['Software'],

  endpoints: (builder) => ({
    getSoftwares: builder.query<
      { data: { result: TSoftware[]; count: number } },
      { limit?: number; page?: number; search?: string }
    >({
      query: (query) => ({
        url: !query.search
          ? `softwares/all?page=${query?.page}&limit=${query?.limit}`
          : `softwares/all?page=${query?.page}&limit=${1000}&search=${query.search}`,
        method: 'GET',
      }),
    }),
    activateSoftware: builder.mutation<{ data: { result: TSoftware[] } }, string>({
      query: (id) => ({ url: `softwares/${id}/activation`, method: 'PATCH' }),
    }),
    deleteSoftware: builder.mutation<{ data: { result: TSoftware[] } }, string>({
      query: (id) => ({ url: `softwares/${id}/`, method: 'DELETE' }),
    }),
    addSoftware: builder.mutation<{ data: { result: TSoftware } }, TSoftware>({
      query: (body) => ({ url: `softwares`, method: 'POST', body }),
    }),
    updateSoftware: builder.mutation<{ data: { result: TSoftware } }, TSoftware>({
      query: (body) => ({ url: `softwares/${body._id}`, method: 'PATCH', body }),
    }),
  }),
});

export const {
  useGetSoftwaresQuery,
  useActivateSoftwareMutation,
  useDeleteSoftwareMutation,
  useAddSoftwareMutation,
  useUpdateSoftwareMutation,
} = softwareApi;
