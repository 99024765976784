import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from './productsQueries';

export type TDevice = {
  name: {
    key: string;
    value: string;
  }[];
  isActive?: boolean;
  order: number;
  _id: string;
};

export const devicesApi = createApi({
  reducerPath: 'devices',
  baseQuery,
  tagTypes: ['Devices'],
  endpoints: (builder) => ({
    getDevices: builder.query<{ data: TDevice[] }, string>({
      query: () => 'devices/all',
    }),
    addDevice: builder.mutation<{ data: TDevice[] }, TDevice>({
      query: (body) => ({
        url: `devices`,
        method: 'POST',
        body,
      }),
    }),
    deleteDevice: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `devices/${id}`,
        method: 'DELETE',
      }),
    }),
    activateDevice: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `devices/${id}/activation`,
        method: 'PATCH',
      }),
    }),
    updateDevice: builder.mutation<{ data: TDevice }, TDevice>({
      query: (body) => ({
        url: `devices/${body._id}/`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useAddDeviceMutation,
  useDeleteDeviceMutation,
  useActivateDeviceMutation,
  useUpdateDeviceMutation,
} = devicesApi;
