import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TCategoryData } from '../../pages/categories/AddACategory';
import { baseQuery } from './productsQueries';

export type TCategory = {
  name: [{ key: string; _id: string; value: string }];
  parent?: TCategory;
  descendants: TCategory[];
  isActive: boolean;
  order: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

export const categoryApi = createApi({
  reducerPath: 'categories',
  baseQuery,
  tagTypes: ['Categories'],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => 'categories/all',
    }),

    getSubCategories: builder.query({
      query: (id: string) => `categories/sub/${id}/all`,
    }),

    // <TCategory, Partial<TCategory> & Pick<TCategory, 'id'>>  mutation type
    activateCategory: builder.mutation<TCategory, string>({
      query: (id: any) => ({
        url: `categories/${id}/activation`,
        method: 'PATCH',

        invalidatesTags: [{ type: 'Category', id: 'LIST' }],
      }),
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      // invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
    }),
    addCategory: builder.mutation<TCategory, TCategoryData>({
      query: (body: TCategoryData) => ({
        url: `categories`,
        method: 'POST',
        body,
      }),
    }),
    deleteCategory: builder.mutation<TCategory, string>({
      query: (id: string) => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
    }),
    updateCategory: builder.mutation<TCategory, TCategory>({
      query: (body) => ({
        url: `categories/${body._id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useActivateCategoryMutation,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = categoryApi;
