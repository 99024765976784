import React from 'react';

const Users = React.lazy(() => import('../pages/Users'));
const Devices = React.lazy(() => import('../pages/devices/Devices'));
const Categories = React.lazy(() => import('../pages/categories/Categories'));
const SubCategories = React.lazy(() => import('../pages/categories/SubCategories'));
const Products = React.lazy(() => import('../pages/products/Products'));
const FeaturedProducts = React.lazy(() => import('../pages/products/FeaturedProducts'));
const LineupProducts = React.lazy(() => import('../pages/products/LineupProducts'));
const AddProduct = React.lazy(() => import('../pages/products/addProduct/AddProduct'));
const UpdateProduct = React.lazy(() => import('../pages/products/UpdateProduct'));
const Software = React.lazy(() => import('../pages/software/Software'));
const AddSoftware = React.lazy(() => import('../pages/software/AddSoftware'));
const UpdateSoftware = React.lazy(() => import('../pages/software/UpdateSoftware'));
const Tutorials = React.lazy(() => import('../pages/tutorials/Tutorials'));

type TRoute = {
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  path: string;
}[];
export const privateRoutes: TRoute = [
  { component: Users, path: '/home/users' },
  { component: Categories, path: '/home/categories' },
  { component: SubCategories, path: '/home/category/:id' },
  { component: Products, path: '/home/products' },
  { component: FeaturedProducts, path: '/home/products/featured/all' },
  { component: LineupProducts, path: '/home/products/lineup/all' },
  { component: AddProduct, path: '/home/products/add-product/' },
  { component: UpdateProduct, path: '/home/products/:id' },
  { component: Devices, path: '/home/devices' },
  { component: Software, path: '/home/software' },
  { component: AddSoftware, path: '/home/software/add-software/' },
  { component: UpdateSoftware, path: '/home/software/:id' },
  { component: Tutorials, path: '/home/tutorials/' },
];
