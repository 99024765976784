import { Form, Input, Button, Row, Col, Typography, Alert, Spin } from 'antd';
import { MailOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useForgotPasswordMutation } from '../../app/queries/userQueries';

const { Title } = Typography;

type TValues = {
  email: string;
};
const ForgotPassword = (): JSX.Element => {
  const [forgotPassword, { isLoading, isSuccess, data, error }]: any = useForgotPasswordMutation();
  const onFinish = (values: TValues) => {
    forgotPassword(values.email);
  };

  return (
    <Row style={{ height: '100vh', overflow: 'hidden' }} justify={'center'} align={'middle'}>
      <Col md={9} xl={6}>
        <Row justify={'center'} style={{ margin: '2rem 0rem' }}>
          <Title level={3}>Forgot your password ? </Title>
          <p>enter your email address to reset password</p>
        </Row>

        <Form
          layout={'vertical'}
          size={'large'}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your Email!',
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <div className="flex justify-center my-3">
            {isLoading && <Spin />}
            {isSuccess && <Alert message={data.message} className="my-3" type="success" showIcon />}
            {error && <Alert message={error.data.message} type="error" showIcon />}
          </div>
          <Form.Item>
            <Button
              block
              style={{ borderRadius: '.7rem' }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              send
            </Button>
          </Form.Item>
          {data?.success && (
            <div className="flex justify-end font-semibold text-lg">
              next <ArrowRightOutlined className="mt-2 pl-2" />
            </div>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
