import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';
import Auth from './slices/Auth';
import { usersApi } from './queries/userQueries';
import { categoryApi } from './queries/categoryQueries';
import { productsApi } from './queries/productsQueries';
import { devicesApi } from './queries/deviceQueries';
import { softwareApi } from './queries/softwareQueries';
import { tutorialApi } from './queries/tutorialQueries';

export const store = configureStore({
  reducer: {
    Auth,
    [usersApi.reducerPath]: usersApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [softwareApi.reducerPath]: softwareApi.reducer,
    [tutorialApi.reducerPath]: tutorialApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(categoryApi.middleware)
      .concat(productsApi.middleware)
      .concat(devicesApi.middleware)
      .concat(softwareApi.middleware)
      .concat(tutorialApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
