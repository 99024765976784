import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TCategory } from './categoryQueries';

export type TProduct = {
  name: {
    key: string;
    value: string;
    _id?: string;
  }[];
  description: {
    key: string;
    value: string;
    _id?: string;
  }[];
  category: TCategory | string;
  images: string[];
  availability: string[];

  title: {
    key: string;
    value: string;
    _id?: string;
  }[];
  features: {
    text: string;
    icon?: string;
  }[];

  body: {
    title: {
      key: string;
      value: string;
      _id?: string;
    }[];
    description: {
      key: string;
      value: string;
      _id?: string;
    }[];
    image: string;
    direction: string;
  }[];
  featuredOrder?: boolean;
  lineupOrder?: boolean;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
};

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.mediastar.co/api/v1/',
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
});

export const productsApi = createApi({
  reducerPath: 'products',
  baseQuery,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query<
      { data: { result: TProduct; count: number } },
      { limit?: number; page?: number; search?: string }
    >({
      query: (query) => ({
        url: !query.search
          ? `products/all?page=${query?.page}&limit=${query?.limit}`
          : `products/all?page=${query?.page}&limit=${1000}&search=${query.search}`,
        method: 'GET',
      }),
    }),
    getFeaturedProducts: builder.query({
      query: () => 'products/featured/all',
    }),
    getLinupProducts: builder.query({
      query: () => 'products/lineup/all',
    }),
    deleteProduct: builder.mutation<undefined, string>({
      query: (id) => ({ url: `products/${id}`, method: 'DELETE' }),
    }),
    addProduct: builder.mutation<TProduct, TProduct>({
      query: (body) => ({ url: `products`, method: 'POST', body }),
    }),
    activateProduct: builder.mutation<TProduct, string>({
      query: (id) => ({ url: `products/${id}/activation`, method: 'PATCH' }),
    }),
    getProductById: builder.query<{ data: TProduct }, string>({
      query: (id: string) => `products/${id}`,
    }),
    updateProduct: builder.mutation<{ data: TProduct }, TProduct>({
      query: (body) => ({ url: `products/${body._id}/`, method: 'PATCH', body }),
    }),
    updateOrder: builder.mutation<{ data: TProduct }, { lineupOrder?: number; featuredOrder?: number; _id: string }>({
      query: (body) => ({ url: `products/${body._id}/`, method: 'PATCH', body }),
    }),
    featureProduct: builder.mutation<TProduct, string>({
      query: (id) => ({ url: `products/${id}/feature`, method: 'PATCH' }),
    }),
    lineupProduct: builder.mutation<TProduct, string>({
      query: (id) => ({ url: `products/${id}/lineup`, method: 'PATCH' }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetFeaturedProductsQuery,
  useGetLinupProductsQuery,
  useDeleteProductMutation,
  useAddProductMutation,
  useActivateProductMutation,
  useGetProductByIdQuery,
  useUpdateProductMutation,
  useFeatureProductMutation,
  useLineupProductMutation,
  useUpdateOrderMutation,
} = productsApi;
