import { Dropdown, Menu, Layout, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { logout } from '../../app/slices/Auth';
import { getUser } from '../../utils/localStorage';
const { Header } = Layout;

const User = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = getUser();

  const handleLogOut = () => {
    dispatch(logout(0));
    history.push('/login');
  };
  const menu = (
    <Menu theme="light">
      <Menu.Item onClick={handleLogOut}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Header className="user_header">
      <span style={{ color: 'black', marginRight: '1rem' }}>Welcome, {user} !</span>
      <Dropdown trigger={['click']} overlay={menu} placement="bottomLeft">
        <span className="avatar_parent">
          <img
            className="user-img"
            src={
              'https://thumbs.dreamstime.com/b/user-icon-trendy-flat-style-isolated-grey-background-user-symbol-user-icon-trendy-flat-style-isolated-grey-background-123663211.jpg'
            }
            style={{ cursor: 'pointer' }}
          />
        </span>
      </Dropdown>
    </Header>
  );
};

export default User;
