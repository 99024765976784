import React, { useState } from 'react';
import {
  PieChartOutlined,
  UnorderedListOutlined,
  UserOutlined,
  InboxOutlined,
  LaptopOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Sider
      className={`${isMobile && 'mobile_nav'}`}
      breakpoint={'md'}
      collapsedWidth={0}
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="light"
    >
      <div className="logo" />
      <Menu  theme="light" defaultSelectedKeys={['2']} mode="inline">
        <Menu.Item key="2" icon={<UserOutlined />}>
          <Link to="/home/users/"> Users </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<UnorderedListOutlined />}>
          <Link to="/home/categories/"> Categories </Link>
        </Menu.Item>

        <SubMenu key="sub1" icon={<InboxOutlined />} title="Products">
          <Menu.Item key="4">
            <Link to="/home/products/">All</Link>
          </Menu.Item>

          <Menu.Item key="5">
            <Link to="/home/products/featured/all">Featured</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/home/products/lineup/all">Lineup</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<LaptopOutlined />} title="Software Updates">
          <Menu.Item key="7">
            <Link to="/home/devices/">Devices</Link>
          </Menu.Item>

          <Menu.Item key="8">
            <Link to="/home/software">Updates</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="9" icon={<PlayCircleOutlined />}>
          <Link to="/home/tutorials/"> Tutorials </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
