import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from './productsQueries';

export type TTutorial = {
  title: {
    key: string;
    value: string;
  }[];

  subtitle: {
    key: string;
    value: string;
  }[];

  videoUrl: string;
  order: 0;
  _id: string;
};

export const tutorialApi = createApi({
  reducerPath: 'tutorial',
  baseQuery: baseQuery,
  tagTypes: ['tutorial'],
  endpoints: (builder) => ({
    getTutorials: builder.query<{ data: { result: TTutorial[] } }, string>({
      query: () => `tutorials/all`,
    }),
    activateTutorial: builder.mutation<TTutorial, string>({
      query: (id) => ({
        url: `tutorials/${id}/activation`,
        method: 'PATCH',
      }),
    }),
    deleteTutorial: builder.mutation<undefined, string>({
      query: (id) => ({
        url: `tutorials/${id}/`,
        method: 'DELETE',
      }),
    }),
    addTutorial: builder.mutation<TTutorial, TTutorial>({
      query: (body) => ({
        url: `tutorials`,
        method: 'POST',
        body,
      }),
    }),
    updateTutorial: builder.mutation<TTutorial, TTutorial>({
      query: (body) => ({
        url: `tutorials/${body._id}`,
        method: 'PATCH',
        body,
      }),
    }),
    featureTutorial: builder.mutation<TTutorial, string>({
      query: (id) => ({
        url: `tutorials/${id}/feature`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetTutorialsQuery,
  useActivateTutorialMutation,
  useDeleteTutorialMutation,
  useAddTutorialMutation,
  useUpdateTutorialMutation,
  useFeatureTutorialMutation,
} = tutorialApi;
