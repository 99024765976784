import { api } from '../api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './productsQueries';

export const usersApi = createApi({
  reducerPath: 'users',
  baseQuery,
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => 'admins',
    }),
    forgotPassword: builder.mutation<unknown, string>({
      query: (email) => ({
        url: `auth/forgot-password`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    deleteUser: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `admins/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetUsersQuery, useForgotPasswordMutation, useDeleteUserMutation } = usersApi;

export type TUser = {
  activated: boolean;
  name: string;
  email: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
};
